import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

// Animations
// import { Pulse } from './animation';

export default createGlobalStyle`
  ${styledNormalize}
  .index_content__6TMNN {
    > div {
      margin: auto;
    }
  }
`;
