import React from 'react';
import { ThemeProvider } from 'styled-components';

// Styles
import Theme from '../assets/styles/theme';
import GlobalStyle from '../assets/styles/global';

const Layout = ({ children }) => (
  <ThemeProvider theme={Theme}>
    {children}
    <GlobalStyle />
  </ThemeProvider>
);

export default Layout;
